/********************** React Table ***********************/

/* Start TABLE Styles */
.ReactTable {
  border: unset;
}

.header__clr {
  color: #b5b5c3;
  font-size: 0.9rem;
}

.Table__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-start;
  padding: 20px 10px;
}

.Table__page {
  display: flex;
  justify-content: flex-end;
  padding: 25px 15px;
}

.Table__visiblePagesWrapper {
  margin-left: 10px;
  margin-right: 10px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 29px;
  width: 29px;
  border-radius: 5px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  padding: 5px;
}

.Table__pageButton--active,
.Table__pageButton:hover {
  color: #fff;
  font-weight: bold;
  -webkit-transition: background-color 3.3s ease;
  background-color: #c63030;
}

.table-status {
  border: 1px solid #e2e5ec !important;
  height: 38px !important;
}

.ReactTable .rt-table {
  overflow: unset;
  overflow-y: visible !important;
}

.ReactTable .rt-tbody {
  overflow: visible !important;
}

.switchPageButton {
  font-size: 15px;
  outline: none;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 29px;
  width: 29px;
  border-radius: 5px;
  background-color: #f0f3ff;
  color: #93a2dd;
  cursor: pointer;
}

.switchPageButton:disabled {
  cursor: not-allowed;
  color: #e0e5f5;
  background-color: #fbfcff;
}

.switchPageButton:disabled:hover {
  cursor: not-allowed;
  color: #f1f4fc;
  font-weight: normal;
  background-color: #fbfcff;
}

.switchPageButton:active,
.switchPageButton:hover {
  color: #fff;
  background-color: #074785;
}

.select-page-text {
  color: #05223d;
  font-size: 13px;
  margin-left: 15px;
}

.select-page {
  border-width: 0;
  color: #93a2dd;
  font-size: 13px;
  font-weight: 600;
  height: 29px;
  padding-left: 10px;
  width: 60px;
  border-radius: 5px;
  /* background: url(../../assets/media/icons/chevron-down.svg) no-repeat right
    #f0f3ff; */
  -webkit-appearance: none;
}

.select-page option {
  font-size: 13px;
  font-weight: bold;
}

.select-page:hover {
  border-width: 0;
  color: #fff;
  background-color: #074785;
}

.select-page:active {
  border-width: 0;
  color: #fff;
  background-color: #c63030;
  -webkit-appearance: none;
}

.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
  background: #c63030;
}

.rt-td {
  color: #6c7293;
  padding: 0;
  overflow: visible !important;
}

.rt-tr-group:hover {
  background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
  box-shadow: none !important;
}

.rt-tr div:not(.-sort-asc):not(.-sort-desc) .rt-resizer::before {
  content: "\2193";
  color: #c63030;
  vertical-align: "center";
  opacity: 0.3;
}
.rt-tr div:not(.-sort-asc):not(.-sort-desc) .rt-resizer::after {
  content: "\2191";
  color: #c63030;
  vertical-align: "center";
  opacity: 0.3;
}

.-sort-asc .rt-resizer::before {
  content: "\2191";
  color: #c63030;
}
.-sort-asc .rt-resizer::after {
  content: "\2193";
  color: #c63030;
  opacity: 0.3;
}
.-sort-desc .rt-resizer::before {
  content: "\2191";
  color: #c63030;
  vertical-align: "center";
  opacity: 0.3;
}
.-sort-desc .rt-resizer::after {
  content: "\2193";
  color: #c63030;
  vertical-align: "center";
}

.ReactTable .rt-resizer {
  top: 5px;
}

.ReactTable .rt-td {
  align-self: center;
}

.Table__page .select-page:hover {
  background-color: #c63030;
}

.rt-resizable-header-content {
  color: #c63030 !important;
  font-weight: 500;
}

.ReactTable .rt-table {
  overflow: unset;
  overflow-y: visible !important;
}

.ReactTable .rt-tbody {
  overflow: visible !important;
}

.rdw-list-dropdown,
.rdw-text-align-dropdown {
  z-index: unset;
}
.ReactTable .rt-expander:after {
  border-top-color: #c63030;
}

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-indeterminate {
  background-color: #c1c1c1 !important;
}
.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-barColorPrimary,
.MuiLinearProgress-bar.MuiLinearProgress-bar2Indeterminate.MuiLinearProgress-barColorPrimary {
  background-color: #c63030 !important;
}

/*** End TABLE Styles ***/ /* Settings Page */
.wizard-step {
  cursor: pointer;
}

.kt-menu__nav li {
  list-style-type: none;
  margin-top: 20px;
}

.secondary-bg {
  background-color: #000000;
  border-color: #000000;
  color: #fff;
}

.table-fixed {
  table-layout: fixed;
}

.vendor-logo {
  background-size: contain !important;
}

/*** Location Input ***/

#input-address {
  width: 100%;
}

.google-suggestion-item {
  cursor: pointer;
  padding: 5px;
  padding-left: 8px;
  font-size: 12px;
  margin: 0px;
}

.google-suggestion-item:hover {
  background-color: #f5f5f5;
}

.google-suggestion-list {
  box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc !important;
}

.google-suggestion-item-manual {
  color: #fa6160;
  text-decoration: underline;
}

.google-suggestion-list {
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  color: black;
}
.google-suggestion-list .google-suggestion-item {
  cursor: default;
  padding: 8px 12px;
  margin-bottom: 0;
}
.google-suggestion-list .google-suggestion-item:hover {
  background-color: #f5f5f5;
}
.google-suggestion-list .google-suggestion-item.google-suggestion-item-manual {
  color: #fa6160;
  text-decoration: underline;
  cursor: pointer;
}

.location-grid {
  display: grid !important;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr) !important;
}

.custom-form-control {
  background: none !important;
  border-radius: 20px !important;
  outline: none !important;
}

.form-control-select {
  outline: none !important;
  border: none !important;
}

.validation-error-message {
  color: #fa6160 !important;
}

.no-cursor {
  cursor: none;
}

/*************** Slider ***************/
.slider-arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #c63030;
  margin-left: 142.5px;
}

/*** Not Found Page Styles ***/
.notFound-container {
  position: relative;
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  gap: 1rem;
  background: white;
  height: 100vh;
  color: white;
  overflow-y: hidden;
  background: #d6d6da;
}

.logo-section img {
  width: 85%;
}

.notFound-container h2 {
  font-size: 50px;
  margin-top: 1rem;
  color: #c63030;
}

.notFound-container h4 {
  font-size: 30px;
  color: black;
}

.notFound-img {
  width: 40%;
  margin-bottom: 1rem;
}

.home-btn {
  font-size: 1.2rem !important;
  margin-top: 1rem;
}

@media (min-width: 700px) {
  .mt-minus-140 {
    margin-top: -140px;
  }
  .d-md-none {
    display: none;
  }
}
@media (max-width: 700px) {
  .w-xs-100 {
    width: 100%;
    margin-bottom: 10px;
  }
  .d-xs-none {
    display: none;
  }
  #category-tabs {
    display: block;
  }
  .table-fixed {
    overflow-y: auto;
    display: block;
  }
  .d-block-xs {
    display: block !important;
  }
}
